// import React, { useEffect, useState } from "react";
// import { useHistory, useParams, Link, Redirect } from "react-router-dom";
// import { getAccessToken } from "../../services/auth/userLocalStorage";
// import { useForm, useFieldArray, Controller } from "react-hook-form";
// import "./response.css";
// import {
//   IonPage,
//   IonContent,
//   IonCard,
//   IonCardHeader,
//   IonCardContent,
//   IonItem,
//   IonLabel,
//   IonLoading,
//   IonList,
//   IonRow,
//   IonCol,
//   IonThumbnail,
//   IonImg,
//   IonChip,
//   IonToast,
//   IonHeader,
//   IonButtons,
//   IonToolbar,
//   IonIcon,
//   IonTitle,
//   IonText,
//   IonGrid,
//   IonButton,
//   IonInput,
// } from "@ionic/react";
// import { arrowBackOutline } from "ionicons/icons";
// import { getOrders } from "../../actions/orderActions";
// import { useDispatch, useSelector } from "react-redux";
// interface TestProps {
//   location?: {
//     state: any;
//   };
//   // Add other props if necessary
// }
// const OrderDetails: React.FC<TestProps> = (props) => {
//   const { id } = useParams<{ id: string }>();
//   const [showToast, setShowToast] = useState(false);
//   const [toastMessage, setToastMessage] = useState("");
//   const [inputChanged, setInputChanged] = useState(false); // State to track if input value has been changed
//   const [bsx, setBsx] = useState(true);
//   const orderId = props?.location?.state;
//   const [order, setOrder] = useState(null);
//   const [orderx, setOrderx] = useState(true);

//   const history = useHistory();
//   const [loading, setLoading] = useState(false);
//   const [status, setStatus] = useState("");
//   const [selectedQuantities, setSelectedQuantities] = useState<{
//     [productId: number]: number;
//   }>({});
//   const [countofchanged, setCountOfChanged] = useState(0);

//   const dispatch = useDispatch();

//   const [updateFlag, setUpdateFlag] = useState(false);
//   const handleUpdateItem = () => {
//     // Logic to update the item
//     // After updating the item, set the update flag in localStorage
//     // localStorage.setItem('updateFlag', 'true');
//     history.push("/app/orders/all");
//     dispatch(getOrders(getAccessToken().token));
//     // setUpdateFlag(true);
//   };
//   // useEffect to listen for changes in the update flag
//   useEffect(() => {
//     const reloadNeeded = localStorage.getItem("updateFlag");
//     if (reloadNeeded) {
//       // If updateFlag is true, reload the page
//       // history.push('/app/orders/test')
//       // window.location.reload();
//       // After reloading, remove the update flag from localStorage

//       localStorage.removeItem("updateFlag");
//     }
//   }, [updateFlag, dispatch]); // This effect runs whenever updateFlag changes

//   const formatDate = (
//     dateTimeString: string,
//     format: Intl.DateTimeFormatOptions
//   ): string => {
//     const formattedDate = new Date(dateTimeString).toLocaleString(
//       "en-IN",
//       format
//     );
//     const capitalizedAM = formattedDate
//       .replace(/-/, " ")
//       .replace(/\b(am|pm)\b/g, (match) => match.toUpperCase());
//     return capitalizedAM.replace(/(\b[a-zA-Z]{3})-(\d{2})/, "$1 $2");
//   };
//   const [numberOfElements, setNumberOfElements] = useState(0);
//   const [sumOfCountPickup, setSumOfCountPickup] = useState(0);
//   const [showAlert, setShowAlert] = useState(false);

//   function formatStatus(status: string): string {
//     return status.replace(/_/g, " ");
//   }

//   const [totalCountPickup, setTotalCountPickup] = useState(0);
//   useEffect(() => {
//     // Calculate total count_pickup when order changes
//     if (order) {
//       const total = order.order_products.reduce(
//         (sum, product) => sum + product.count_pickup,
//         0
//       );
//       setTotalCountPickup(total);
//     }
//   }, [order]);

//   const handleCountPickupChange = (index: number, value: number) => {
//     // Update count_pickup value for a specific product
//     const updatedOrder = { ...order };
//     updatedOrder.order_products[index].count_pickup = value;
//     setOrder(updatedOrder);
//   };

//   useEffect(() => {
//     const fetchOrderDetails = async () => {
//       setLoading(true);
//       setOrderx(false);

//       try {
//         const response = await fetch(
//           `https://api.citylinencare.com/orders/${orderId}`,
//           {
//             headers: {
//               Authorization: `Bearer ${getAccessToken().token}`,
//               "Content-Type": "application/json",
//             },
//           }
//         );

//         const data = await response.json();
//         console.log("API response:", data); // Log the entire API response
//         if (order?.data) {
//           console.log("1st comment for order data test");
//           console.log(order?.data);
//         }
//         if (response.ok) {
//           setOrder(data.data);
//           console.log(order);

//           setNumberOfElements(data.data.order_products.length);
//           setSumOfCountPickup(
//             data.data.order_products.reduce(
//               (sum, product) => sum + product.count_pickup,
//               0
//             )
//           );
//           setStatus(data.data.status);
//         } else {
//           console.error("Error fetching order details. API response:", data);
//         }
//       } catch (error) {
//         console.error("Error fetching order details:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchOrderDetails();
//   }, [orderId]);

//   const {
//     setValue,
//     control,
//     handleSubmit,
//     setError,
//     formState: { errors },
//   } = useForm({});

//   const { fields, append, remove } = useFieldArray({
//     control,
//     name: "order_products",
//   });

//   useEffect(() => {
//     if (true) {
//       console.log("1st comment for order data test");
//       console.log(order);
//       // setCountVerification(Number(order?.data?.count_pickup));
//       setValue(
//         "order_products",
//         order?.order_products.map((product: any) => {
//           console.log("product in 3", product.count_pickup);
//           let ob = {
//             id: product.id,
//             count_pickup: product.count_pickup,
//             // count_out_for_delivery:product.count_pickup,
//             notes_pickup: product.notes_pickup,
//           };
//           console.log("this is ob", ob);
//           return ob;
//         })
//       );
//     }
//   }, [order]);

  

//   const onSubmit = async (data: any) => {
//     // if (!order) return;

//     setLoading(true);
//     try {
//       // console.log(data)
//       const updatedOrder = {
//         business_id: order.business_id,
//         status: order?.status,
//         weight_at_pickup_gms: order.weight_at_pickup_gms,
//         order_products: data.order_products,
//       };

//       console.log("updated order form ", updatedOrder);
//       const response = await fetch(
//         `https://api.citylinencare.com/orders/${id}`,
//         {
//           method: "PUT",
//           headers: {
//             Authorization: `Bearer ${getAccessToken().token}`,
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(updatedOrder),
//         }
//       );

//       const datax = await response.json();
//       console.log("data in response is", datax);

//       if (response.ok) {
//         if (false) {
//           setToastMessage("Please change a field to update");

//           setShowToast(true);
//         } else {
//           setToastMessage("Order updated successfully.");
//           handleUpdateItem();

//           // history.push("/app/orders/test");

//           setShowToast(true);
//         }

//         console.log(showToast);

//         setSelectedQuantities({});
//       } else {
//         console.log("hello");
//         setToastMessage("Order not updated successfully.");
//         setShowToast(true);

//         throw new Error("Failed to update order");
//       }
//     } catch (error) {
//       console.error("Error:", error.message);
//       setToastMessage("Failed to update order. Please try again.");

//       setShowToast(true);
//       console.error("Error updating order:", error);
//     } finally {
//       setLoading(false);
//     }
//   };
//   //   console.log(fields)

//   //  console.log(fields);
//   const handleToastDismiss = () => {
//     setToastMessage("");
//     setShowToast(false);
//     // history.push('/app/orders/act'); // Redirect to the same place
//   };




//   return (
//     <IonPage>
//       <IonHeader>
//         <IonToolbar>
//           <IonButtons slot="start">
//             <IonIcon
//               size="large"
//               onClick={(e) => history.goBack()}
//               icon={arrowBackOutline}
//             />
//           </IonButtons>
//           <IonTitle>Update Order</IonTitle>{" "}
//         </IonToolbar>
//       </IonHeader>
//       <IonContent>
//         {order && (
//           <form className="responsive-width" onSubmit={handleSubmit(onSubmit)}>
//             <IonCard
//               className=""
//               style={{
//                 marginTop: "16px",
//               }}
//             >
//               <IonCardHeader>
//                 <IonRow className="ion-align-items-center">
//                   <IonCol sizeMd="2" size="2.7">
//                     <img
//                       src={order.business.logo_display_url}
//                       alt="Business Logo"
//                       style={{
//                         height: "80px",
//                         width: "80px",
//                         borderRadius: "8px",
//                         // border: "2px solid gray",
//                       }}
//                     />
//                   </IonCol>
//                   <IonCol sizeMd="3" size="4">
//                     <IonItem lines="none">{order.business.name}</IonItem>
//                   </IonCol>
//                   <IonCol sizeMd="7" size="5.3">
//                     {/* {order?.data && ( */}
//                     {/* <div
//               style={{
//                 display: "flex",
//                 flexDirection: "row-reverse",
//                 zIndex: "-2333333",
//                 marginLeft: "10vw"
//               }}
//             > */}<div
// 												style={{
// 													textAlign: 'end',
// 												}}
// 											>
//                     <IonLabel className="ion-margin-left ion-align-items-center">
                    
//                       {formatDate(order.created_at, {
//                         day: "2-digit",
//                         month: "short",
//                         year: "2-digit",
//                         hour: "numeric",
//                         minute: "2-digit",
//                         hour12: true,
//                       })}
//                       {/* </h2> */}
//                     </IonLabel></div>
//                     {/* </div> */}
//                   </IonCol>
//                 </IonRow>
//               </IonCardHeader>

//               <IonCardContent>
//                 <div
//                   style={{
//                     display: "flex",
//                     flexDirection: "row-reverse",
//                     marginRight: "1.5vw",
//                   }}
//                 >
//                   {/* {order?.data && order?.data?.status === 'NEW' && ( */}
//                   <IonChip slot="end">
//                     <div
//                       style={{
//                         width: "auto",
//                         height: "auto",
//                         display: "flex",
//                         justifyContent: "center",
//                         // fontSize: '3vh',
//                         marginLeft: "0",
//                       }}
//                     >
//                       {formatStatus(order?.status)}
//                       {/* status */}
//                     </div>
//                   </IonChip>
//                   {/* )} */}
//                 </div>




//                 <div
//                   style={{
//                     overflowX: "auto",
//                     overflowY: "hidden",
//                   }}
//                 >
//                   <div
//                     style={{
//                       minWidth: "820px",
//                       overflowX: "auto",
//                     }}
//                   >
//                     <IonList>
//                       <IonItem>
//                         <IonGrid>
//                           <IonRow className="ion-align-items-center ion-text-center ">
//                             <IonCol size="4" className="ioncol-style">
//                               <IonLabel className="ion-margin-start">
//                                 <h2
//                                   style={{
//                                     fontWeight: "bold",
//                                   }}
//                                 >
//                                   Product
//                                 </h2>
//                               </IonLabel>
//                             </IonCol>
//                             <IonCol size="8">
//                               <IonItem>
//                                 <IonGrid>
//                                   <IonRow className="ion-text-center ">
//                                     <IonCol size="12">
//                                       <IonLabel>
//                                         <h2
//                                           style={{
//                                             fontWeight: "bold",
//                                           }}
//                                         >
//                                           Counts
//                                         </h2>
//                                       </IonLabel>
//                                     </IonCol>
//                                   </IonRow>
//                                 </IonGrid>
//                               </IonItem>
//                               <IonGrid>
//                                 <IonRow>
//                                   <IonCol size="2" className={"ioncol-style"}>
//                                     <IonLabel>
//                                       <h2
//                                         style={{
//                                           fontWeight: "bold",
//                                         }}
//                                       >
//                                         Pickup
//                                       </h2>
//                                     </IonLabel>
//                                   </IonCol>
//                                   <IonCol size="2" className={"ioncol-style"}>
//                                     <IonLabel>
//                                       <h2
//                                         style={{
//                                           fontWeight: "bold",
//                                         }}
//                                       >
//                                         Verify
//                                       </h2>
//                                     </IonLabel>
//                                   </IonCol>
//                                   <IonCol size="2" className={"ioncol-style"}>
//                                     <IonLabel>
//                                       <h2
//                                         style={{
//                                           fontWeight: "bold",
//                                         }}
//                                       >
//                                         Dispatch
//                                       </h2>
//                                     </IonLabel>
//                                   </IonCol>
//                                   <IonCol size="3" className={"ioncol-style"}>
//                                     <IonLabel>
//                                       <h2
//                                         style={{
//                                           fontWeight: "bold",
//                                         }}
//                                       >
//                                         Out For Delivery
//                                       </h2>
//                                     </IonLabel>
//                                   </IonCol>
//                                   <IonCol size="2">
//                                     <IonLabel>
//                                       <h2
//                                         style={{
//                                           fontWeight: "bold",
//                                         }}
//                                       >
//                                         Delivered
//                                       </h2>
//                                     </IonLabel>
//                                   </IonCol>
//                                 </IonRow>
//                               </IonGrid>
//                             </IonCol>
//                           </IonRow>
//                         </IonGrid>
//                       </IonItem>
//                     </IonList>

//                     {/* product mapping? */}

//                     {order.order_products.map((product: any, index: number) => (
//                       <div key={product.id}>
//                         <IonGrid>
//                           <IonRow>
//                             <IonCol size="4" className="ioncol-style">
//                               <IonItem className=" ion-text-center">
//                                 <IonImg
//                                   src={product.image_display_url}
//                                   style={{ height: "50px", width: "50px" }}
//                                   alt={product.name}
//                                 />
//                                 <IonLabel>
//                                   <h4>{product.name}</h4>
//                                 </IonLabel>
//                               </IonItem>
//                             </IonCol>
//                             <IonGrid>
//                               <IonRow>
//                                 <IonCol size="2" className="ion-text-center">
//                                   {/* <h3><IonCol size='2' className='ion-text-center'> <h3>{product?.count_pickup ? product.count_pickup : "-"}</h3></IonCol></h3> */}

//                                   {order.status == "NEW" ? (
//                                     <IonLabel
//                                       className="ion-text-center"
//                                       style={{
//                                         borderBottom: "1px solid ",
//                                         border: "0px solid blue",
//                                       }}
//                                     >
//                                       <IonItem
//                                         lines="inset"
//                                         className="ion-no-padding  ion-text-center"
//                                       >
//                                         <Controller
//                                           control={control}
//                                           name={`order_products.${index}.count_pickup`}
//                                           defaultValue={product.count_pickup}
//                                           render={({ field }) => (
//                                             <IonInput
//                                               className="ion-text-center "
//                                               value={field.value}
//                                               //  disabled={product.status != 'NEW' }
//                                               style={{ width: "100%" }}
//                                               inputmode="numeric"
//                                               pattern="[0-9]*"
//                                               onKeyPress={(e) => {
//                                                 // Get the pressed key
//                                                 const key = e.key;

//                                                 // Check if the pressed key is not a number
//                                                 if (!/^\d$/.test(key)) {
//                                                   // Prevent the default action (typing the character)
//                                                   e.preventDefault();
//                                                 }
//                                               }}
//                                               onIonChange={(e) => {
//                                                 handleCountPickupChange(
//                                                   index,
//                                                   parseInt(field.value || 0)
//                                                 ); // Parse input value as integer

//                                                 if (
//                                                   !isNaN(Number(e.target.value))
//                                                 ) {
//                                                   field.onChange(
//                                                     Number(e.detail.value)
//                                                   );
//                                                 } else {
//                                                   setError(
//                                                     `order_products.${index}.count_pickup`,
//                                                     {
//                                                       type: "pattern",
//                                                       message: "Only numbers",
//                                                     }
//                                                   );
//                                                 }
//                                               }}
//                                             />
//                                           )}
//                                         />
//                                       </IonItem>
//                                     </IonLabel>
//                                   ) : (
//                                     <IonLabel
//                                       className="ion-text-center"
//                                       style={{
//                                         borderBottom: "1px solid ",
//                                         border: "0px solid blue",
//                                       }}
//                                     >
//                                       <IonItem
//                                         lines="inset"
//                                         className="ion-no-padding  ion-text-center"
//                                       >
//                                         <IonInput
//                                           className="ion-text-center "
//                                           value={product.count_pickup}
//                                           disabled={product.status != "NEW"}
//                                           style={{ width: "100%" }}
//                                         />
//                                       </IonItem>
//                                     </IonLabel>
//                                   )}
//                                 </IonCol>
//                                 <IonCol size="2" className="ion-text-center">
//                                   <h3>
//                                     <IonCol
//                                       size="2"
//                                       className="ion-text-center"
//                                     >
//                                       <h3>
//                                         {product?.count_verification
//                                           ? product.count_verification
//                                           : "--"}
//                                       </h3>
//                                     </IonCol>
//                                   </h3>
//                                 </IonCol>
//                                 <IonCol size="2" className="ion-text-center">
//                                   <h3>
//                                     <IonCol
//                                       size="2"
//                                       className="ion-text-center"
//                                     >
//                                       <h3>
//                                         {product?.count_ready_for_dispatch
//                                           ? product.count_ready_for_dispatch
//                                           : "--"}
//                                       </h3>
//                                     </IonCol>
//                                   </h3>
//                                 </IonCol>

//                                 <IonCol size="2" className="ion-text-center">
//                                   <h3>
//                                     <IonCol
//                                       size="2"
//                                       className="ion-text-center"
//                                     >
//                                       <h3>
//                                         {false
//                                           ? product.count_ready_for_dispatch
//                                           : "--"}
//                                       </h3>
//                                     </IonCol>
//                                   </h3>
//                                 </IonCol>

//                                 <IonCol size="3" className="ion-text-center">
//                                   <h3>
//                                     <IonCol
//                                       size="3"
//                                       className="ion-text-center"
//                                     >
//                                       <h3>
//                                         {false
//                                           ? product.count_ready_for_dispatch
//                                           : "--"}
//                                       </h3>
//                                     </IonCol>
//                                   </h3>
//                                 </IonCol>
//                               </IonRow>
//                             </IonGrid>
//                           </IonRow>
//                         </IonGrid>
//                       </div>
//                     ))}






















//                     <IonGrid>
//                       <IonRow className="ion-align-items-center">
//                         <IonCol sizeMd="4" size="4" className="ion-text-center">
//                           {" "}
//                           <h2>Total ({numberOfElements})</h2>{" "}
//                         </IonCol>

//                         <IonCol
//                           sizeMd="1.3"
//                           size="1.3"
//                           className="ion-text-center"
//                         >
//                           <h2>{totalCountPickup}</h2>
//                         </IonCol>
//                         <IonCol
//                           sizeMd="1.5"
//                           size="1.5"
//                           className="ion-text-center"
//                         >
//                           --
//                         </IonCol>
//                         <IonCol
//                           sizeMd="1.2"
//                           size="1.1"
//                           className="ion-text-center"
//                         >
//                           --
//                         </IonCol>
//                         <IonCol
//                           sizeMd="1.3"
//                           size="1.5"
//                           className="ion-text-center"
//                         >
//                           --
//                         </IonCol>
//                         <IonCol
//                           sizeMd="2.1"
//                           size="1.8"
//                           className="ion-text-center"
//                         >
//                           --
//                         </IonCol>
//                       </IonRow>
//                     </IonGrid>
//                   </div>
//                 </div>

//                 <br />
//                 <IonGrid className="ion-text-center ion-margin-vertical">
//                   {/* {isVerified && ( */}

//                   <div className="ion-text-center">
//                     {order?.status == "NEW" && (
//                       <Link
//                         to={
//                           {
//                             // pathname:`/app/orders/all`,
//                             // state: { orderId: orderId },
//                           }
//                         }
//                       >
//                         <IonButton type="submit" shape="round">
//                           <IonText>Update Order</IonText>
//                         </IonButton>
//                       </Link>
//                     )}
//                   </div>
//                 </IonGrid>
//               </IonCardContent>
//             </IonCard>
//           </form>
//         )}

//         <IonToast
//           isOpen={showToast}
//           message={toastMessage}
//           onDidDismiss={handleToastDismiss} // Call handleToastDismiss when toast is dismissed
//           duration={2000}
//           position="top"
//         ></IonToast>
//       </IonContent>
//     </IonPage>
//   );
// };

// export default OrderDetails;

import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link, Redirect } from "react-router-dom";
import { getAccessToken } from "../../services/auth/userLocalStorage";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import "./response.css";
import {
  IonPage,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonItem,
  IonLabel,
  IonLoading,
  IonList,
  IonRow,
  IonCol,
  IonThumbnail,
  IonImg,
  IonChip,
  IonToast,
  IonHeader,
  IonButtons,
  IonToolbar,
  IonIcon,
  IonTitle,
  IonText,
  IonGrid,
  IonButton,
  IonInput,
} from "@ionic/react";
import { arrowBackCircleOutline, arrowBackOutline } from "ionicons/icons";
import { getOrders } from "../../actions/orderActions";
import { useDispatch, useSelector } from "react-redux";
interface TestProps {
  location?: {
    state: any;
  };
  // Add other props if necessary
}
const OrderDetails: React.FC<TestProps> = (props) => {
  const { id } = useParams<{ id: string }>();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [inputChanged, setInputChanged] = useState(false); // State to track if input value has been changed
  const [bsx, setBsx] = useState(true);
  const orderId = props?.location?.state;
  const [order, setOrder] = useState(null);
  const [orderx, setOrderx] = useState(true);

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [selectedQuantities, setSelectedQuantities] = useState<{
    [productId: number]: number;
  }>({});
  const [countofchanged, setCountOfChanged] = useState(0);

  const dispatch = useDispatch();

  const [updateFlag, setUpdateFlag] = useState(false);
  const handleUpdateItem = () => {
    // Logic to update the item
    // After updating the item, set the update flag in localStorage
    // localStorage.setItem('updateFlag', 'true');
	setLoading(true);
    history.push("/app/orders/all");
	setLoading(false);
    dispatch(getOrders(getAccessToken().token));
    // setUpdateFlag(true);
  };
  // useEffect to listen for changes in the update flag
  useEffect(() => {
    const reloadNeeded = localStorage.getItem("updateFlag");
    if (reloadNeeded) {
      // If updateFlag is true, reload the page
      // history.push('/app/orders/test')
      // window.location.reload();
      // After reloading, remove the update flag from localStorage

      localStorage.removeItem("updateFlag");
    }
  }, [updateFlag, dispatch]); // This effect runs whenever updateFlag changes

  const formatDate = (
    dateTimeString: string,
    format: Intl.DateTimeFormatOptions
  ): string => {
    const formattedDate = new Date(dateTimeString).toLocaleString(
      "en-IN",
      format
    );
    const capitalizedAM = formattedDate
      .replace(/-/, " ")
      .replace(/\b(am|pm)\b/g, (match) => match.toUpperCase());
    return capitalizedAM.replace(/(\b[a-zA-Z]{3})-(\d{2})/, "$1 $2");
  };
  const [numberOfElements, setNumberOfElements] = useState(0);
  const [sumOfCountPickup, setSumOfCountPickup] = useState(0);
  const [showAlert, setShowAlert] = useState(false);

  function formatStatus(status: string): string {
    return status.replace(/_/g, " ");
  }

  const [totalCountPickup, setTotalCountPickup] = useState(0);
  useEffect(() => {
    // Calculate total count_pickup when order changes
    if (order) {
      const total = order.order_products.reduce(
        (sum, product) => sum + product.count_pickup,
        0
      );
      setTotalCountPickup(total);
    }
  }, [order]);

  const handleCountPickupChange = (index: number, value: number) => {
    // Update count_pickup value for a specific product
    const updatedOrder = { ...order };
    updatedOrder.order_products[index].count_pickup = value;
    setOrder(updatedOrder);
  };

  useEffect(() => {
    const fetchOrderDetails = async () => {
      setLoading(true);
      setOrderx(false);

      try {
        const response = await fetch(
          `https://api.citylinencare.com/orders/${id}`,
          {
            headers: {
              Authorization: `Bearer ${getAccessToken().token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        console.log("API response:", data); // Log the entire API response
        if (order?.data) {
          console.log("1st comment for order data test");
          console.log(order?.data);
        }
        if (response.ok) {
          setOrder(data.data);
          console.log(order);

          setNumberOfElements(data.data.order_products.length);
          setSumOfCountPickup(
            data.data.order_products.reduce(
              (sum, product) => sum + product.count_pickup,
              0
            )
          );
          setStatus(data.data.status);
        } else {
          console.error("Error fetching order details. API response:", data);
        }
      } catch (error) {
        console.error("Error fetching order details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  const {
    setValue,
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({});

  const { fields, append, remove } = useFieldArray({
    control,
    name: "order_products",
  });

  useEffect(() => {
    if (true) {
    //   console.log("1st comment for order data test");
      console.log(order);
      // setCountVerification(Number(order?.data?.count_pickup));
      setValue(
        "order_products",
        order?.order_products.map((product: any) => {
          console.log("product in 3", product.count_pickup);
          let ob = {
            id: product.id,
            count_pickup: product.count_pickup,
            // count_out_for_delivery:product.count_pickup,
            notes_pickup: product.notes_pickup,
          };
          console.log("this is ob", ob);
          return ob;
        })
      );
    }
  }, [order]);


  const onSubmit = async (data: any) => {
    // if (!order) return;

    setLoading(true);
    try {
      // console.log(data)
      const updatedOrder = {
        business_id: order.business_id,
        status: order?.status,
        weight_at_pickup_gms: order.weight_at_pickup_gms,
        order_products: data.order_products,
      };

      console.log("updated order form ", updatedOrder);
      const response = await fetch(
        `https://api.citylinencare.com/orders/${id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${getAccessToken().token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedOrder),
        }
      );

      const datax = await response.json();
      console.log("data in response is", datax.message);

      if (response.ok) {
        if (false) {
          setToastMessage("Please change a field to update");

          setShowToast(true);
        } else {
          setToastMessage(datax.message);
          handleUpdateItem();

          setShowToast(true);
        }

        console.log(showToast);

        setSelectedQuantities({});
      } else {
        console.log("hello");
        setToastMessage(datax.message);
        setShowToast(true);

        throw new Error("Failed to update order");
      }
    } catch (error) {
      console.error("Error:", error.message);
      setToastMessage("Failed to update order. Please try again.");

      setShowToast(true);
      console.error("Error updating order:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleToastDismiss = () => {
    setToastMessage("");
    setShowToast(false);
    // history.push('/app/orders/act'); // Redirect to the same place
  };
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonIcon
							size="large"
							onClick={e => history.goBack()}
							icon={arrowBackOutline}
						/>
					</IonButtons>
					<IonTitle>Order #{id} Details</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{ (
					<form
						className="responsive-width"
						onSubmit={handleSubmit(onSubmit)}
					>
						<IonCard
							className=""
							style={{
								marginTop: '16px',
							}}
						>
							<IonCardHeader>
								<IonRow className="ion-align-items-center">
									<IonCol sizeMd="2" size="2.7">
										<img
											 src={order?.business?.logo_display_url}
											alt="Business Logo"
											style={{
												height: '60px',
												width: '60px',
												borderRadius: '8px',
											}}
										/>
									</IonCol>
									<IonCol sizeMd="3" size="4">
										<IonItem lines="none">
											{order?.business?.name}
											
										</IonItem>
									</IonCol>
									<IonCol sizeMd="7" size="5.3">
										{order && (
											<div
											style={{
												textAlign: 'end',
											}}
										>
				<IonLabel className="ion-margin-left ion-align-items-center">
				
				  {formatDate(order.created_at, {
					day: "2-digit",
					month: "short",
					year: "2-digit",
					hour: "numeric",
					minute: "2-digit",
					hour12: true,
				  })}
				  
				</IonLabel></div>
										)}
									</IonCol>
								</IonRow>
							</IonCardHeader>

							<IonCardContent>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row-reverse',
										marginRight: '1.5vw',
									}}
								>
									<IonChip slot="end">
									<div
                      style={{
                        width: "auto",
                        height: "auto",
                        display: "flex",
                        justifyContent: "center",
                        // fontSize: '3vh',
                        marginLeft: "0",
                      }}
                    >
                      {/* {formatStatus(order?.status)} */}
					  {order?.status}
                     
                    </div>
									</IonChip>
									{/* )} */}
								</div>
										<IonList>
											<IonItem>

												<IonGrid>
													<IonRow className="ion-align-items-center ion-text-center ">
														<IonCol size='5'
														sizeMd='4' 
														className="ioncol-style">
														
														<IonItem>
																<IonGrid>
																	<IonRow className="ion-text-center ">
																		<IonCol size="12">
																			<IonLabel>
																				<h2
																					style={{
																						fontWeight: 'bold',
																					}}
																				>
																					Product
																				</h2>
																			</IonLabel>
																		</IonCol>
																	</IonRow>
																</IonGrid>
															</IonItem>
															

															<div style={{marginTop:"30px"}}></div>
																{order?.order_products.map(
											(product: any, index: number) => {
												return (	<>
												
												<IonRow key={product.id}>

<IonCol size='12'  className=" ion-justify-content-center ion-align-items-center"
																		style={{
																			display: 'flex',
																			padding:'13px'
																		}}>
																
																		{/* <IonItem> */}
																			<IonThumbnail className="ion-margin-end ion-hide-sm-down">
																			<img src={product.image_display_url} style={{
												height: '48px',
												width: '44px',
												borderRadius: '8px',
											}}/>
																		</IonThumbnail>
																		{/* <IonLabel className="ion-text-wrap "> */}
																		<IonItem>	<IonLabel>
																			{product.name}
																		</IonLabel> </IonItem>
                                    </IonCol>
																	</IonRow></>
															
												)})}
                        <IonRow>
                          <IonCol size="12" className=" ion-justify-content-center ion-align-items-center ion-text-center">
                          {" "}
                          <h2>Total ({numberOfElements})</h2>{" "}
                          </IonCol></IonRow>
                     
														</IonCol>
														


														<IonCol size="7" sizeMd='8'  >
															
															<IonItem>
																<IonGrid>
																	<IonRow className="ion-text-center ">
																		<IonCol size="12">
																			<IonLabel>
																				<h2
																					style={{
																						fontWeight: 'bold',
																					}}
																				>
																					Counts
																				</h2>
																			</IonLabel>
																		</IonCol>
																	</IonRow>

																	
																</IonGrid>
															</IonItem>
															
							
<div style={{overflowX: 'auto',
										overflowY: 'hidden',}}>
	<div style={{minWidth:"300px"}}>
<IonRow>
																	<IonCol size="2.4" className={'ioncol-style'}>
																		<IonLabel>
																			<h2
																				style={{
																					fontWeight: 'bold',
																				}}
																			>
																				Pickup
																			</h2>
																		</IonLabel>
																	</IonCol>
																	<IonCol size="2.4"  className={'ioncol-style'}>
																		<IonLabel>
																			<h2
																				style={{
																					fontWeight: 'bold',
																				}}
																			>
																				Verify
																			</h2>
																		</IonLabel>
																	</IonCol>
																	<IonCol size="2.4" className={'ioncol-style'}>
																		<IonLabel>
																			<h2
																				style={{
																					fontWeight: 'bold',
																				}}
																			>
																				Dispatch
																			</h2>
																		</IonLabel>
																	</IonCol>
                                  <IonCol size="2.4" className={'ioncol-style'}>
																		<IonLabel>
																			<h2
																				style={{
																					fontWeight: 'bold',
																				}}
																			>
																				Out For Delivery
																			</h2>
																		</IonLabel>
																	</IonCol>
																	<IonCol  size="2.4">
																		<IonLabel>
																			<h2
																				style={{
																					fontWeight: 'bold',
																				}}
																			>
																				Delivered
																			</h2>
																		</IonLabel>
																	</IonCol>
																</IonRow>
															
																{order?.order_products.map(
											(product: any, index: number) => {
												return (	
												<IonRow key={product.id}>
{/* 
<IonCol size="2.4"  className=" ion-justify-content-center ion-align-items-center" 
																		style={{
																			display: 'flex',
																		}}>
																		<IonItem
																			lines={'none'}
																			disabled={order?.status !== 'NEW'}
																		>
																			<IonLabel className="ion-text-center">
																				{product.count_pickup}
																			</IonLabel>
																		</IonItem>
																	</IonCol> */}

																	{/* <IonCol size="2.4"  className=" ion-justify-content-center ion-align-items-center" 
																		style={{
																			display: 'flex',
																		}}>
																		<IonItem
																			lines={'none'}
																			disabled={order?.status !== 'NEW'}
																		>
																			<IonLabel className="ion-text-center">
																				{product.count_verification}
																			</IonLabel>
																		</IonItem>
																	</IonCol> */}
																	{/* <IonCol size="2.4"  className=" ion-justify-content-center ion-align-items-center" 
																		style={{
																			display: 'flex',
																		}}>
																		<IonItem
																			lines={'none'}
																			disabled={order?.status !== 'NEW'}
																		>
																			<IonLabel className="ion-text-center">
																				{product?.count_ready_for_dispatch || ' -- '}
																				
																			</IonLabel>
																		</IonItem>
																	</IonCol> */}
																	<IonCol
																		size="2.4"
																		className=" ion-justify-content-center ion-align-items-center"
																		style={{
																			display: 'flex',
																		}}
																	>
																		<IonItem
																	
																		>

																			 	<IonLabel>
																					 <Controller
                                          control={control}
                                          name={`order_products.${index}.count_pickup`}
                                          defaultValue={product.count_pickup}
                                          render={({ field }) => (
                                            <IonInput
                                              className="ion-text-center "
                                              value={field.value}
                                              //  disabled={product.status != 'NEW' }
                                              style={{ width: "100%" }}
                                              inputmode="numeric"
                                              pattern="[0-9]*"
                                              onKeyPress={(e) => {
                                                // Get the pressed key
                                                const key = e.key;

                                                // Check if the pressed key is not a number
                                                if (!/^\d$/.test(key)) {
                                                  // Prevent the default action (typing the character)
                                                  e.preventDefault();
                                                }
                                              }}
                                              onIonChange={(e) => {
                                                handleCountPickupChange(
                                                  index,
                                                  parseInt(field.value || 0)
                                                ); // Parse input value as integer

                                                if (
                                                  !isNaN(Number(e.target.value))
                                                ) {
                                                  field.onChange(
                                                    Number(e.detail.value)
                                                  );
                                                } else {
                                                  setError(
                                                    `order_products.${index}.count_pickup`,
                                                    {
                                                      type: "pattern",
                                                      message: "Only numbers",
                                                    }
                                                  );
                                                }
                                              }}
                                            />
                                          )}
                                        />
																				
																				</IonLabel> 
																				
																			

																		</IonItem>
																	</IonCol>
																	
																	<IonCol
																		size="2.4"
																		className=" ion-justify-content-center ion-align-items-center"
																		style={{
																			display: 'flex',
																		}}
																	>
																		<IonItem
																		lines="none"
																		>
																			 
																				<IonLabel className="ion-text-center ">
																					{' -- '}
																				</IonLabel>
																			
																		</IonItem>
																	</IonCol>
																	<IonCol
																		size="2.4"
																		className=" ion-justify-content-center ion-align-items-center"
																		style={{
																			display: 'flex',
																		}}
																	>
																		<IonItem
																		lines="none"
																		>
																			 
																				<IonLabel className="ion-text-center ">
																					{' -- '}
																				</IonLabel>
																			
																		</IonItem>
																	</IonCol>
																	<IonCol
																		size="2.4"
																		className=" ion-justify-content-center ion-align-items-center"
																		style={{
																			display: 'flex',
																		}}
																	>
																		<IonItem
																		lines="none"
																		>
																			 
																				<IonLabel className="ion-text-center ">
																					{' -- '}
																				</IonLabel>
																			
																		</IonItem>
																	</IonCol>
																	<IonCol
																		size="2.4"
																		className=" ion-justify-content-center ion-align-items-center"
																		style={{
																			display: 'flex',
																		}}
																	>
																		<IonItem
																		lines="none"
																		>
																			 
																				<IonLabel className="ion-text-center ">
																					{' -- '}
																				</IonLabel>
																			
																		</IonItem>
																	</IonCol>
																	
																	</IonRow>
															
												)})}
                       
                        <IonRow className="ion-align-items-center">

{/* <IonCol  size="4" className="ion-text-center
">
  {" "}
  <h2>Total ({numberOfElements})</h2>{" "}
</IonCol> */}


<IonCol
 
  size="2.5"
  className="ion-text-center"
>
  <h2>{totalCountPickup}</h2>
</IonCol>
<IonCol
 
  size="2.3"
  className="ion-text-center"
>
  --
</IonCol>
<IonCol
 
  size="2.5"
  className="ion-text-center"
>
  --
</IonCol>
<IonCol

  size="2.3"
  className="ion-text-center"
>
  --
</IonCol>
<IonCol
  size="2.4"
  className="ion-text-center"
>
  --
</IonCol>



                        </IonRow>
															
															</div>											
</div>
												
															
															
														</IonCol>
													
													</IonRow>
												</IonGrid>
											</IonItem>
											
											<IonGrid>

                      <IonRow className="ion-align-items-center">

                        {/* <IonCol  size="4" className="ion-text-center
						">
                          {" "}
                          <h2>Total ({numberOfElements})</h2>{" "}
                        </IonCol> */}

{/* 
<IonCol
                         
                          size="1.8"
                          className="ion-text-center"
                        >
                          <h2>{totalCountPickup}</h2>
                        </IonCol>
                        <IonCol
                         
                          size="1.5"
                          className="ion-text-center"
                        >
                          --
                        </IonCol>
                        <IonCol
                         
                          size="1.5"
                          className="ion-text-center"
                        >
                          --
                        </IonCol>
                        <IonCol
                       
                          size="1.5"
                          className="ion-text-center"
                        >
                          --
                        </IonCol>
                        <IonCol
                          size="1.5"
                          className="ion-text-center"
                        >
                          --
                        </IonCol>

                        */}
                      </IonRow>
                    </IonGrid>
										</IonList>
										

								<br />
							
								<IonGrid className="ion-text-center ion-margin-vertical">
                  {/* {isVerified && ( */}

                  <div className="ion-text-center">
                    {order?.status == "NEW" && (
                      <Link
                        to={
                          {
                            // pathname:`/app/orders/all`,
                            // state: { orderId: orderId },
                          }
                        }
                      >
                        <IonButton type="submit" shape="round">
                          <IonText>Update Order</IonText>
                        </IonButton>
                      </Link>
                    )}
                  </div>
                </IonGrid>
							</IonCardContent>
						</IonCard>
					</form>
				
				)}
			    <IonLoading
        isOpen={loading}
        message={'Please wait...'}
      />
		
				</IonContent>

			<IonToast
				isOpen={showToast}
				message={toastMessage}
				onDidDismiss={() => {
					setToastMessage('');
					setShowToast(false);
				}}
				duration={5000}
				position="top"
			></IonToast>
		</IonPage>
	);
};

export default OrderDetails;