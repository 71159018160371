import {
	IonButton,
	IonButtons,
	IonCard,
	IonCheckbox,
    IonCardContent,
    IonCardHeader,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonItemDivider,
	IonImg,
	IonLabel,
	IonList,
	IonLoading,
	IonModal,
	IonPage,
	IonPopover,
	IonRadio,
	IonRadioGroup,
	IonRow,
	IonSelect,
	IonText,
	IonTextarea,
	IonTitle,
	IonToast,
	IonToolbar,
	isPlatform,
} from '@ionic/react';import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAccessToken } from '../../services/auth/userLocalStorage';

const UpdateOrder: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [order, setOrder] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [selectedQuantities, setSelectedQuantities] = useState<{ [productId: number]: number }>({});

    useEffect(() => {
        const fetchOrderDetails = async () => {
            setLoading(true);

            try {
                const response = await fetch(`https://api.citylinencare.com/orders/${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${getAccessToken().token}`,
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();
                console.log('API response:', data); // Log the entire API response

                if (data && data.data) {
                    setOrder(data.data);
                    // Initialize selectedQuantities with original quantities
                    const initialQuantities: { [productId: number]: number } = {};
                    data.data.order_products.forEach((product: any) => {
                        initialQuantities[product.id] = product.quantity;
                    });
                    setSelectedQuantities(initialQuantities);
                } else {
                    console.error('Error fetching order details. API response:', data);
                }
            } catch (error) {
                console.error('Error fetching order details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchOrderDetails();
    }, [id]);



    const updateOrder = async () => {
        if (!order) return;

        setLoading(true);

        try {
            const orderProducts = Object.keys(selectedQuantities).map(productId => ({
                id: productId,
                count_pickup: selectedQuantities[productId],
                count_verification: selectedQuantities[productId],
                count_ready_for_dispatch:0,
                count_out_for_delivery:0,
                notes_pickup: "this is by me2"
              }));
          
              const orderData = {
                business_id: order.business_id,
                status:"NEW",
                weight_at_pickup_gms:0,
                order_products: orderProducts,
             
                
              };
            // const updatedOrder = { ...order, status: "DELIVERED" }; // Update status to DELIVERED
            // console.log("order",order);
            // console.log("updatedorder",updatedOrder);
            console.log("orderProducts",orderData)
            const response = await fetch(`https://api.citylinencare.com/orders/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${getAccessToken().token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(orderData),
            });
         
            const data = await response.json();
            console.log('Updated Order:',orderData); 
            console.log("data in response is",data);
            // Optionally, update the state with the updated order
            // setOrder(data.data);
        } catch (error) {
            console.error('Error updating order:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleQuantityChange = (e: CustomEvent, productId: number) => {
        const quantity = parseInt(e.detail.value as string, 10);
        setSelectedQuantities({ ...selectedQuantities, [productId]: quantity });
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Order Id #{id}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                {order && (
                    <>
                    <IonCard>

                    <IonCardHeader>
                    <div style={{display:"flex"}}>
                            <img src={order.business.logo_display_url} style={{height:"80px",width:"80px"}} alt={order.business.name} />
                            <h2>{order.business.name}</h2>
                        </div>
                    </IonCardHeader>
                       
<IonCardContent>
{order.order_products.map((product: any) => (
                            
                            <div key={product.id}>
                                <IonGrid style={{border:"1px solid grey",borderRadius:"8px"}}>
                                    <IonRow >
                                        <IonCol size='4' >
                                        <img src={product.image_display_url}  style={{height:"50px",width:"50px",marginLeft:"20px"}} alt={product.name}   />
                                        </IonCol>
                                <IonCol size='4' style={{display:"flex",alignItems:"center"}}>
                                <h1>{product.name}</h1>
                                </IonCol>
                               <IonCol size='4'>
                                <IonInput
                                    type="number"
                                    value={selectedQuantities[product.id] } // Use original quantity as default
                                    onIonChange={(e) => handleQuantityChange(e, product.id)}
                                    placeholder='enter the count'
                                /></IonCol>

</IonRow>
</IonGrid>
                            </div>
                        ))}
</IonCardContent>
                       
</IonCard>
<div className='ion-text-center'>
<IonButton onClick={updateOrder} disabled={loading}>
                            Update Order
                        </IonButton>
</div>
                      
                    </>
                )}
            </IonContent>
        </IonPage>
    );
};

export default UpdateOrder;
