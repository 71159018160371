// import { ErrorMessage } from '@hookform/error-message';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardMenu from '../../components/dashboard/DashboardMenu';
import {
	getOrdersByStatus,
	createAdjustments,
	editAdjustments,
	deleteAdjustments,
	changeOrderStaffFunction,
	changeOrderStatusFunction,
} from '../../actions/dashboardActions';
import {
	arrowBack as arrowBackIcon,
	shareSocial as shareSocialIcon,
	callOutline as callOutlineIcon,
	navigateOutline as navigateOutlineIcon,
	documentText as documentTextIcon,
	pencil as pencilIcon,
	trash as trashIcon,
	closeOutline,
} from 'ionicons/icons';
import {
	IonLoading,
	IonIcon,
	IonCol,
	IonHeader,
	IonPage,
	IonToolbar,
	IonTitle,
	IonContent,
	IonCard,
	IonCardContent,
	IonList,
	IonItem,
	IonButton,
	IonSelect,
	IonSelectOption,
	IonButtons,
	IonBackButton,
	IonLabel,
	IonGrid,
	IonRow,
	IonText,
	IonImg,
	IonModal,
	IonInput,
	useIonViewDidEnter,
	useIonViewWillEnter,
	useIonViewDidLeave,
	IonRadioGroup,
	IonRadio,
	IonAlert,
	IonThumbnail,
	IonChip,
	IonCardHeader,
	InputChangeEventDetail,
	IonToast,
} from '@ionic/react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { formatDate } from '../../services/formatters';
import { Share } from '@capacitor/share';
import { useHistory, useLocation, useParams } from 'react-router';
import { caretDown as caretDownIcon } from 'ionicons/icons';
import Title from '../../components/dashboard/Header';
import { editOrderStatus,getOrdersById,getOrders } from '../../actions/orderActions';
import { getAccessToken } from '../../services/auth/userLocalStorage';

interface TestProps {
  location?: {
    state: any;
  };
}
const DeliveredOrder: React.FC<TestProps> = (props) => {
	const [showAlert, setShowAlert] = useState(false);
	const { id } = useParams<{ id: string }>();
//   const orderId = props?.location?.state;

	const dispatch = useDispatch();
	const [showToast, setShowToast] = useState<boolean>(false);
	const [toastMessage, setToastMessage] = useState<string>('');

	const { orderLoading, order } = useSelector((state: any) => state.orderById);
	const [isUpdated, setIsUpdated] = useState<boolean>(false);

	const {
		setValue,
		control,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm({});
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'order_products',
	});

	const { updateOrderMessage, updateOrderLoading } = useSelector(
		(state: any) => state.updateOrders
	);


	const [updateFlag, setUpdateFlag] = useState(false);
	const [loading, setLoading] = useState(false);
  
	const handleUpdateItem = () => {
		// Logic to update the item
		// After updating the item, set the update flag in localStorage
		// localStorage.setItem('updateFlag', 'true');
		setLoading(true);
		history.push("/app/orders/act");
		setLoading(false);
		dispatch(getOrders(getAccessToken().token));
		// setUpdateFlag(true);
	  };
	  // useEffect to listen for changes in the update flag
	  useEffect(() => {
		const reloadNeeded = localStorage.getItem("updateFlag");
		if (reloadNeeded) {
		  // If updateFlag is true, reload the page
		  // history.push('/app/orders/test')
		  // window.location.reload();
		  // After reloading, remove the update flag from localStorage
	
		  localStorage.removeItem("updateFlag");
		}
	  }, [updateFlag, dispatch]); // This effect runs whenever updateFlag changes
	const [is_out_for_delivery, setIsOutForDelivery] = useState(false);
	const [is_ready_for_dispatch, setIsReadyForDispatch] = useState(false);

	const history = useHistory();

	const location = useLocation();
	useEffect(() => {
		if (order?.data) {
			setIsOutForDelivery(order?.data?.status === 'OUT_FOR_DELIVERY');
			setIsReadyForDispatch(order?.data?.status === 'READY_FOR_DISPATCH');
			// console.log(order?.data?.count_pickup);
			// setCountVerification(Number(order?.data?.count_pickup));
			setValue(
				'order_products',
				order?.data?.order_products.map((product: any) => {
					// console.log(product?.count_verification);
					return {
						id: product.id,
						count_pickup: product.count_pickup,
						count_verification: product.count_verification,
						count_ready_for_dispatch: product.count_ready_for_dispatch,
						count_out_for_delivery: product.count_out_for_delivery,
						count_delivered: product.count_delivered,
						notes_pickup: product.notes_pickup,
					};
				})
			);
		}
	}, [order]);
	// console.log(order?.data?.status);
	useEffect(() => {
		if (updateOrderMessage?.message && isUpdated) {
			// history.push('/app/orders/act');
			handleUpdateItem();
			setShowToast(true);
			setToastMessage(updateOrderMessage?.message);
		}
	}, [updateOrderMessage]);

	const onSubmit = (data: any) => {
		const updatedOrder = {
			business_id: order.data.business_id,
			status:
				order?.data?.status === 'READY_FOR_DISPATCH' ? 'OUT_FOR_DELIVERY' : 'DELIVERED',
			weight_at_pickup_gms: order.data.weight_at_pickup_gms,
			order_products: data.order_products,
		};
		console.log("this is the data",data.order_products);
		console.log(updatedOrder);

		dispatch(editOrderStatus(getAccessToken().token, id, updatedOrder));
		setIsUpdated(true);
	};

	useEffect(() => {
		dispatch(getOrdersById(getAccessToken().token, id));
    console.log("hello",id)
	console.log(id);
	}, [id, updateOrderMessage?.message, isUpdated]);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonIcon
							size="large"
							onClick={e => history.goBack()}
							icon={arrowBackIcon}
						/>
					</IonButtons>
					<IonTitle>Order #{id} Details</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{order?.data ? (
					<form
						className="responsive-width"
						onSubmit={e => {
							e.preventDefault();
							setShowAlert(true);
						}}
					>
						<IonCard
							className=""
							style={{
								marginTop: '16px',
							}}
						>
							<IonCardHeader>
								<IonRow className="ion-align-items-center">
									<IonCol sizeMd="2" size="2.7">
										<img
											src={order.data.business.logo_display_url}
											alt="Business Logo"
											style={{
												height: '60px',
												width: '60px',
												borderRadius: '8px',
											}}
										/>
									</IonCol>
									<IonCol sizeMd="3" size="4">
										<IonItem lines="none">
											{/* <h4 style={{}}> */}
											{order.data.business.name}
											{/* Order #{order.data.id} */}
											{/* </h4> */}
										</IonItem>
									</IonCol>
									<IonCol sizeMd="7" size="5.3">
										{order?.data && (
											<div
												style={{
													textAlign: 'end',
												}}
											>
												<IonLabel className="">
													{/* <h1 
													style={{
														color: 'black',
														paddingTop: '10px',
													}}
												>*/}
													{formatDate(
														order.data.created_at,
														'DD MMM YY, hh:mm A'
													)}
													{/* </h1> */}
												</IonLabel>
											</div>
										)}
									</IonCol>
								</IonRow>
							</IonCardHeader>

							<IonCardContent>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row-reverse',
										marginRight: '1.5vw',
									}}
								>
									<IonChip slot="end">
										

										{order.data.status === 'READY_FOR_DISPATCH'
											? 'READY FOR DISPATCH'
											: order.data.status}
									
									</IonChip>
									{/* )} */}
								</div>
							
										<IonList>
											<IonItem>

												<IonGrid>
													<IonRow className="ion-align-items-center ion-text-center ">
														<IonCol size='5'
														sizeMd='4' 
														className="ioncol-style">
														
														<IonItem>
																<IonGrid>
																	<IonRow className="ion-text-center ">
																		<IonCol size="12">
																			<IonLabel>
																				<h2
																					style={{
																						fontWeight: 'bold',
																					}}
																				>
																					Product
																				</h2>
																			</IonLabel>
																		</IonCol>
																	</IonRow>
																</IonGrid>
															</IonItem>
															

															<div style={{marginTop:"30px"}}></div>
																{order?.data?.order_products.map(
											(product: any, index: number) => {
												return (	<>
												
												<IonRow key={product.id}>

<IonCol size='12'  className=" ion-justify-content-center ion-align-items-center"
																		style={{
																			display: 'flex',
																			padding:'13px'
																		}}>
																
																		{/* <IonItem> */}
																			<IonThumbnail className="ion-margin-end ion-hide-sm-down">
																			<img src={product.image_display_url} style={{
												height: '48px',
												width: '44px',
												borderRadius: '8px',
											}}/>
																		</IonThumbnail>
																		{/* <IonLabel className="ion-text-wrap "> */}
																		<IonItem>	<IonLabel>
																			{product.name}
																		</IonLabel> </IonItem></IonCol>
																	</IonRow></>
															
												)})}
															




														</IonCol>
														


														<IonCol size="7" sizeMd='8'  >
															
															<IonItem>
																<IonGrid>
																	<IonRow className="ion-text-center ">
																		<IonCol size="12">
																			<IonLabel>
																				<h2
																					style={{
																						fontWeight: 'bold',
																					}}
																				>
																					Counts
																				</h2>
																			</IonLabel>
																		</IonCol>
																	</IonRow>

																	
																</IonGrid>
															</IonItem>
															
							
<div style={{overflowX: 'auto',
										overflowY: 'hidden',}}>
	<div style={{minWidth:"300px"}}>
<IonRow>
																	<IonCol size="2.4" className={'ioncol-style'}>
																		<IonLabel>
																			<h2
																				style={{
																					fontWeight: 'bold',
																				}}
																			>
																				Pickup
																			</h2>
																		</IonLabel>
																	</IonCol>
																	<IonCol size="2.4"  className={'ioncol-style'}>
																		<IonLabel>
																			<h2
																				style={{
																					fontWeight: 'bold',
																				}}
																			>
																				Verify
																			</h2>
																		</IonLabel>
																	</IonCol>
																	<IonCol size="2.4" className={'ioncol-style'}>
																		<IonLabel>
																			<h2
																				style={{
																					fontWeight: 'bold',
																				}}
																			>
																				Dispatch
																			</h2>
																		</IonLabel>
																	</IonCol>
                                  <IonCol size="2.4" className={'ioncol-style'}>
																		<IonLabel>
																			<h2
																				style={{
																					fontWeight: 'bold',
																				}}
																			>
																				Out For Delivery
																			</h2>
																		</IonLabel>
																	</IonCol>
																	<IonCol  size="2.4">
																		<IonLabel>
																			<h2
																				style={{
																					fontWeight: 'bold',
																				}}
																			>
																				Delivered
																			</h2>
																		</IonLabel>
																	</IonCol>
																</IonRow>
															
																{order?.data?.order_products.map(
											(product: any, index: number) => {
												return (	
												<IonRow key={product.id}>

<IonCol size="2.4"  className=" ion-justify-content-center ion-align-items-center" 
																		style={{
																			display: 'flex',
																		}}>
																		<IonItem
																			lines={'none'}
																			disabled={order?.data?.status !== 'NEW'}
																		>
																			<IonLabel className="ion-text-center">
																				{product.count_pickup}
																			</IonLabel>
																		</IonItem>
																	</IonCol>

																	<IonCol size="2.4"  className=" ion-justify-content-center ion-align-items-center" 
																		style={{
																			display: 'flex',
																		}}>
																		<IonItem
																			lines={'none'}
																			disabled={order?.data?.status !== 'NEW'}
																		>
																			<IonLabel className="ion-text-center">
																				{product.count_verification}
																			</IonLabel>
																		</IonItem>
																	</IonCol>
																	<IonCol size="2.4"  className=" ion-justify-content-center ion-align-items-center" 
																		style={{
																			display: 'flex',
																		}}>
																		<IonItem
																			lines={'none'}
																			disabled={order?.data?.status !== 'NEW'}
																		>
																			<IonLabel className="ion-text-center">
																				{product?.count_ready_for_dispatch || ' -- '}
																				
																			</IonLabel>
																		</IonItem>
																	</IonCol>
																	<IonCol
																		size="2.4"
																		className=" ion-justify-content-center ion-align-items-center"
																		style={{
																			display: 'flex',
																		}}
																	>
																		<IonItem
																		lines={is_ready_for_dispatch ? 'full' : 'none'}
																		disabled={!is_ready_for_dispatch}
																		// readonly={!is_ready_for_dispatch}
																		>
																			 
																			 {
																			  is_ready_for_dispatch ? 

																			 (	<IonLabel>
																					<Controller
																						control={control}
																					
																						name={`order_products.${index}.count_out_for_delivery`}
																						render={({ field }) => (
																							<IonInput
																							    readonly={!is_ready_for_dispatch}
																								className="ion-text-center"
																								value={field.value}
																								style={{ width: '100%' }}
																								onIonChange={e => {
																									if (
																										!isNaN(
																											Number(e.target.value)
																										)
																									) {
																										field.onChange(
																											Number(e.detail.value)
																										);
																									} else {
																										setError(
																											`order_products.${index}.count_out_for_delivery`,
																											{
																												type: 'pattern',
																												message: 'Only numbers',
																											}
																										);
																									}
																								}}
																							/>
																						)}
																					/>
																				
																				</IonLabel>) : (<IonLabel className="ion-text-center ion-justify-content-center ion-align-items-center">
																					<IonItem lines='none'>
																					<IonInput style={{ width: '100%' }} disabled={true}>
																					{product?.count_out_for_delivery ||
																						' -- '}</IonInput></IonItem>
																				</IonLabel>) }
																			

																		</IonItem>
																	</IonCol>
																	
																	<IonCol
																		size="2.4"
																		className=" ion-justify-content-center ion-align-items-center"
																		style={{
																			display: 'flex',
																		}}
																	>
																		<IonItem
																			lines={is_out_for_delivery ? 'full' : 'none'}
																			disabled={!is_out_for_delivery}
																		>
																			{is_out_for_delivery ? (
																				<IonLabel>
																					<Controller
																						control={control}
																					
																						name={`order_products.${index}.count_delivered`}
																						render={({ field }) => (
																							<IonInput
																								className="ion-text-center"
																								value={field.value}
																								style={{ width: '100%' }}
																								onIonChange={e => {
																									if (
																										!isNaN(
																											Number(e.target.value)
																										)
																									) {
																										field.onChange(
																											Number(e.detail.value)
																										);
																									} else {
																										setError(
																											`order_products.${index}.count_delivered`,
																											{
																												type: 'pattern',
																												message: 'Only numbers',
																											}
																										);
																									}
																								}}
																							/>
																						)}
																					/>
																				</IonLabel>
																			) : (
																				<IonLabel className="ion-text-center ">
																					{product?.count_delivered ||
																						' -- '}
																				</IonLabel>
																			)}
																		</IonItem>
																	</IonCol>
																	
																	{/* <IonCol  className=" ion-justify-content-center ion-align-items-center"
																																			
																																			size="2.4"
																		style={{
																			display: 'flex',
																		}}>
																		<IonItem
																			lines={'none'}
																			disabled={order?.data?.status !== 'NEW'}
																		>
																			<IonLabel className="ion-text-center">
																				{' -- '}
																			</IonLabel>
																		</IonItem>
																	</IonCol> */}

                                {/* <IonCol size="2.4" className=" ion-justify-content-center ion-align-items-center"
																										

																		style={{
																			display: 'flex',
																		}}>
																		<IonItem
																			lines={'none'}
																			disabled={order?.data?.status !== 'NEW'}
																		>
																			<IonLabel className="ion-text-center">
																				{' -- '}
																			</IonLabel>
																		</IonItem>
																	</IonCol>
																	 */}
																	
																	</IonRow>
															
												)})}
															
															</div>											
</div>
												
															
															
														</IonCol>
													
													</IonRow>
												</IonGrid>
											</IonItem>
										</IonList>
										

								<br />
								<IonGrid className="ion-text-center ion-margin-vertical">
									{
                  is_ready_for_dispatch &&
                   (
										<IonButton
											shape="round"
											type="submit"
											disabled={orderLoading || updateOrderLoading}
											onClick={() => {
												setIsReadyForDispatch(false);
											}}
										>
											<IonText color={'light'}>Out For Delivery</IonText>
										</IonButton>
									)}
									{
                  is_out_for_delivery &&
                   (
										<IonButton
											shape="round"
											type="submit"
											disabled={orderLoading || updateOrderLoading}
										>
											<IonText color={'light'}>Deliver</IonText>
										</IonButton>
									)}
								</IonGrid>
							</IonCardContent>
						</IonCard>
					</form>
				) : (
					<IonText
						className={`ion-align-items-center ion-text-center ${
							orderLoading === true ? 'ion-hide' : ''
						}`}
					>
						<h5>No order for your Action.vfsfv</h5>
					</IonText>
				)}
				<IonLoading
					isOpen={orderLoading || updateOrderLoading}
					message={'Please wait...'}
				/>
				<IonAlert
					isOpen={showAlert === true}
					onDidDismiss={() => setShowAlert(false)}
					header={`Confirm ${
						is_ready_for_dispatch ? 'Out For Delivery' : 'Delivered'
					} `}
					message={`I've verified the count for all products`}
					cssClass={'custom-alert'}
					buttons={[
						{
							cssClass: '',
							text: 'CANCEL',
							role: 'cancel',
						},
						{
							cssClass: 'alert-button-confirm',
							text: 'OK',
							handler: handleSubmit(onSubmit),
						},
					]}
				/>
				  <IonLoading
        isOpen={loading}
        message={'Please wait...'}
      />
			</IonContent>
			<IonToast
				isOpen={showToast}
				message={toastMessage}
				onDidDismiss={() => {
					setToastMessage('');
					setShowToast(false);
				}}
				duration={5000}
				position="top"
			></IonToast>
		</IonPage>
	);
};

export default DeliveredOrder;